#home {
  .banner {
    overflow: hidden;
    .img-checo {
      max-width: 35rem;
      transform: translateX(10%);
    }
    .logo-checo {
      width: calc(50% + -4rem);
      max-width: 20rem;
      left: calc(50% - 1.5rem);
      transform: translate(-100%, 3rem);
    }
  }

  .info {
    .description-card {
      min-height: 100%;
      border-radius: 2rem 2rem 0 0;
      box-shadow: 0px .333rem .333rem rgba(0, 0, 0, 0.25);
      margin-top: -2rem;
      background-color: var(--color-white);
    }

    .text-user {
      text-transform: uppercase;
      font-weight: 600;
    }

  }
  @media screen and (min-width: 769px) {
    .banner {
      .img-checo {
        max-width: max(20rem, 23.5vw);
        margin-right: calc(20% - max(12.5rem, 16vw));
        transform: none;
      }
      .logo-checo {
        width: 75%;
        max-width: 41.85rem;
        bottom: -10%;
        left: auto;
        transform: none;
        right: calc(22% - max(12.5rem, 16vw) + max(20rem, 23.5vw));
      }
    }
    .info {
      flex-direction: row-reverse;
      .description-card {
        margin-top: 0;
        .description-card-content {
          max-width: 24rem;
        }
      }
      .home-tutorial .bg-tutorial {
        display: none;
      }
    }
  }
}

