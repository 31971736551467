main {
	min-height: calc(100vh - 35.55rem);
}

.btns {
	max-width: 100%;
	padding: 1.333rem 1.5rem;
	border-radius: 4rem;
	cursor: pointer;
	--color: var(--color-white);
	font-weight: 600;
	font-size: 1.16rem;
	text-transform: uppercase;
	transition-duration: 0.2s;
	transition-property: box-shadow, transform, opacity;
	will-change: transform;
	overflow: hidden;
	background-color: var(--color-blue);

	&:focus-visible {
		transform: scale(1.025);
		box-shadow: 0px 0px 0.25rem 0px var(--color-black);
	}

	&[class*='-red'] {
		background-color: var(--color-red);
	}

	img {
		&:first-child {
			margin-right: 1rem;
		}

		&:last-child {
			margin-left: 1rem;
		}
	}

	@media (hover: hover) {
		&:hover {
			opacity: .85;
			transform: scaleY(1.065);
		}
	}
}

.input-group-checkbox {
	label {
		--color: var(--color-black);
		column-gap: 1rem;

		input {
			width: 1.25rem;
			height: 1.25rem;
			border: 1px solid var(--icon, #B4BCC8);
			border-radius: 2px;
			flex: 0 0 auto;
			background-color: var(--color-white);

			&:checked {
				background-color: var(--color-blue);
			}
		}
	}

	.error-message {
		padding-left: 2.5rem;
	}
}

.input-group {
	&:not(:first-child) {
		margin-top: 1.5rem;
	}

	.flex {
		row-gap: .666rem;
		flex-direction: column-reverse;
	}

	input {
		padding: 1rem 2rem 1rem 4rem;
		border: 1px solid rgba(6, 30, 71, 0.30);
		border-radius: 2rem;
		box-shadow: var(--shadow);
		font-size: 1.25rem;
		background-color: var(--color-white);
		background-size: 2rem;
		background-repeat: no-repeat;
		background-position: 1.5rem center;

		&[name*='email'] {
			background-image: url('../../../public/assets/images/global/i-mail-outline.svg');
		}

		&[name*='pass'] {
			background-image: url('../../../public/assets/images/global/i-lock-closed-outline.svg');
		}

		&[name*='name'] {
			background-image: url('../../../public/assets/images/global/i-person-outline.svg');
		}

		&[name*='tel'] {
			background-image: url('../../../public/assets/images/global/i-call-outline.svg');
		}
	}

	label {
		padding: 0 2.5rem;
		color: var(--color-blue);
		font-size: 1rem;
		font-weight: 500;
		opacity: .5;
	}

	.toggle-pass {
		height: max-content;
		right: 1.5rem;
	}

	.error-message {
		padding-left: 2.5rem;
	}
}

.bg {
	pointer-events: none;
}

.home-tutorial .logo {
	max-width: 23.5rem;
}

.success {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
	transition-duration: .333s;
	transition-property: opacity, visibility;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		opacity: .9;
		background-color: var(--color-blue);
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}

	img {
		max-width: 7.5rem;
		margin: 6rem auto;
	}

	.btns {
		max-width: 85%;
	}
}

.error-message {
	color: var(--color-red);
}

.loader-container {
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.loader {
	width: 3.333rem;
	aspect-ratio: 1;
	animation:
		l3-1 1.5s infinite linear,
		l3-2 2.5s infinite steps(1) -.5s;
}

.modal {
	z-index: 6;
	background-color: rgba($color: #ffffff, $alpha: 0.5);
	position: fixed;

	.close-btn {
		width: 3rem;
		height: 3rem;
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
		z-index: 5;

		.close-icon {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			cursor: pointer;
			background-color: var(--color-blue);

			&::before,
			&::after {
				content: '';
				width: 1rem;
				height: 1px;
				margin: auto;
				position: absolute;
				inset: 0;
				transform: rotate(45deg);
				background-color: var(--color-white);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}
	}

	.swiper {
		position: absolute;
		width: 100%;
		height: 100%;

		.img-step {
			max-width: max-content;
			height: 80%;
			margin-top: 5rem;
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	color: var(--color-blue) !important;
}

.swiper-pagination-bullet {
	background-color: var(--color-blue) !important;
	opacity: 1 !important;
}
.swiper-pagination-bullet-active {
	background-color: var(--color-red) !important;
}

.card-animation {
	--line-thicknes: 3px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	z-index: 3;
	user-select: none;
	pointer-events: none;
	transition-duration: .25s;
	transition-property: opacity, visibility;
	background: hsl(234, 41%, 15%);
	&::before,&::after, .line {
		content: '';
		position: absolute;
		animation: .8s .6s linear infinite
	}
	&::before, &::after {
		width: var(--line-thicknes);
		height: 100%
	}
	.line {
		width: 100%;
		height: var(--line-thicknes);
		animation-delay: 1.2s;
	}
	&::before {
		left: 0;
		bottom: 0;
		transform: translateY(100%);
		animation-name: to-top;
		background: linear-gradient(to bottom, hsl(185, 81%, 54%), transparent);
	}
	&::after {
		right: 0;
		top: 0;;
		transform: translateY(-100%);
		animation-name: to-bottom;
		background: linear-gradient(to bottom, transparent, hsl(185, 81%, 54%));
	}
	.line.top {
		top: 0;
		left: 0;
		transform: translateX(-100%);
		animation-name: to-right;
		background: linear-gradient(to right, transparent, hsl(185, 81%, 54%));
	}
	.line.bottom {
		bottom: 0;
		right: 0;
		transform: translateX(100%);
		animation-name: to-left;
		background: linear-gradient(to right, hsl(185, 81%, 54%), transparent);
	}
	&.show {
		opacity: 1;
		visibility: visible;
	}
}

ol {
	counter-reset: li-counter-parent;
	
	> li {
		counter-increment: li-counter-parent;
	}
	ol {
		counter-reset: li-counter-child;
		> li {
			display: flex;
			align-items: baseline;
			column-gap: .5rem;
			counter-increment: li-counter-child;
			&::before {
				content: counter(li-counter-parent)'.'counter(li-counter-child);
				font-size: 1rem;
			}
		}
	}

	ul {
		li {
			list-style: none;
		}
	} 
}

@keyframes l3-1 {
	0% {
		transform: perspective(12.5rem) rotateX(0deg) rotateY(0deg)
	}

	50% {
		transform: perspective(12.5rem) rotateX(180deg) rotateY(0deg)
	}

	100% {
		transform: perspective(12.5rem) rotateX(180deg) rotateY(180deg)
	}
}

@keyframes l3-2 {
	0% {
		background: var(--color-red)
	}

	33% {
		background: var(--color-blue)
	}

	66% {
		background: var(--color-light-blue)
	}
}
  
@keyframes to-top{
  to {
    transform: translateY(-100%);
  }
}
@keyframes to-bottom{
  to {
    transform: translateY(100%);
  }
}
@keyframes to-left{
  to {
    transform: translateX(-100%);
  }
}
@keyframes to-right{
  to {
    transform: translateX(100%);
  }
}

@media (width > 768px) {
	main {
		min-height: calc(100vh - 12.1rem);
	}
}

@media (width >= 551px) {
	.input-group input {
		background-size: 1.5rem;
	}
}